import { Matrix } from '@/Models'
import matrixModule from '@/store/matrix'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class QuadrantName extends Vue {
  north_quadrant_name = 'Estrategias'
  south_quadrant_name = 'proyectos'
  west_quadrant_name = 'Objetivos'
  east_quadrant_name = 'Indicadores'
  mounted() {
    const matrix = matrixModule.matrix
    if (matrix) {
      this.north_quadrant_name =
        matrix.north_quadrant && matrix.north_quadrant.name
          ? matrix.north_quadrant.name
          : this.north_quadrant_name
      this.south_quadrant_name =
        matrix.south_quadrant && matrix.south_quadrant.name
          ? matrix.south_quadrant.name
          : this.south_quadrant_name
      this.west_quadrant_name =
        matrix.west_quadrant && matrix.west_quadrant.name
          ? matrix.west_quadrant.name
          : this.west_quadrant_name
      this.east_quadrant_name =
        matrix.east_quadrant && matrix.east_quadrant.name
          ? matrix.east_quadrant.name
          : this.east_quadrant_name
    }
  }

  getData() {
    let matrix = matrixModule.matrix
    if (matrix) {
      matrix = matrix as Matrix
      if (matrix.north_quadrant)
        matrix = {
          ...matrix,
          north_quadrant: {
            ...matrix.north_quadrant,
            name: this.north_quadrant_name,
          },
        }
      else
        matrix = {
          ...matrix,
          north_quadrant: {
            id: 0,
            name: this.north_quadrant_name,
            content: [],
          },
        }

      if (matrix.south_quadrant)
        matrix = {
          ...matrix,
          south_quadrant: {
            ...matrix.south_quadrant,
            name: this.south_quadrant_name,
          },
        }
      else
        matrix = {
          ...matrix,
          south_quadrant: {
            id: 0,
            name: this.south_quadrant_name,
            content: [],
          },
        }

      if (matrix.west_quadrant)
        matrix = {
          ...matrix,
          west_quadrant: {
            ...matrix.west_quadrant,
            name: this.west_quadrant_name,
          },
        }
      else
        matrix = {
          ...matrix,
          west_quadrant: {
            id: 0,
            name: this.west_quadrant_name,
            content: [],
          },
        }

      if (matrix.east_quadrant)
        matrix = {
          ...matrix,
          east_quadrant: {
            ...matrix.east_quadrant,
            name: this.east_quadrant_name,
          },
        }
      else
        matrix = {
          ...matrix,
          east_quadrant: {
            id: 0,
            name: this.east_quadrant_name,
            content: [],
          },
        }

      if (!matrix.resources_quadrant)
        matrix = {
          ...matrix,
          resources_quadrant: {
            id: 0,
            name: 'Resource Quadrant',
            content: [],
          },
        }
    }
    return matrix
  }

  async validate() {
    if (!this.$refs.observer_quadrant_name) return
    const isValid = await (this.$refs.observer_quadrant_name as any).validate()
    return isValid ? this.getData() : null
  }
}
